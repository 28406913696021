<template>
  <div class="fond">
    <div class="image-fond">
      
    <!-- Début pour photo de garde -->
    <div class="container-fluid depart pt-5 desktop">

    </div>
    <!-- Fin pour photo de garde -->

    <!-- Engagement -->
    <div class="container-fluid mt-4">
      <div class="row justify-content-center text-center">
        <div class="col-md-10 col-lg-5 mb-3">
          <div class="card shadow-lg rounded">
            <div class="card-header couleurCard">
              <h2>Engagement</h2>
            </div>
            <div class="card-body">
              <h3>Déplacement rapide et gratuit</h3>
              <p>Réponse à votre demande en 1h par mail ou téléphone</p>
              <p>📱 06.73.43.81.99   📧 microrepaire@gmail.com</p>
            </div>
          </div>
        </div>
        <div class="col-md-10 col-lg-5 mb-3">
          <div class="card shadow-lg rounded">
            <div class="card-header couleurCard">
              <h2>Conseil</h2>
            </div>
            <div class="card-body">
              <h3>Conseil et suivi des interventions</h3>
              <p>Hotline gratuite après réparation</p>
            </div>
          </div>
        </div>
      </div>
      <!-- horaire et information du site -->
      <div class="container-fluid mt-4 pb-4">
      <div class="row justify-content-center text-center">
        <div class="col-md-10 col-lg-4 mb-3">
          <div class="card shadow-lg rounded">
            <div class="card-header couleurCard">
              <h2>Horaires</h2>
            </div>
            <div class="card-body">
              <h3>Horaires du magasin</h3>
              <ul>
                <li>Lundi 16h30 à 18h30</li>
                <li>Mardi 16h30 à 18h30</li>
                <li>Mercredi 14h00 à 19h00</li>
                <li>Jeudi 16h30 à 19h00</li>
                <li>Vendredi 16h30 à 18h30</li>
              </ul>
              <!-- <h3>Horaires d'intervention à domicile ou sur site</h3> -->
              <h3>Horaires Hotline et déplacement</h3>
              <ul>
                <li>Du lundi au vendredi</li>
                <!-- <li>De 9h à 19h</li> -->
                <li>De 9h à 16h30</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-10 col-lg-4">
          <div class="card shadow-lg rounded">
            <div class="card-header couleurCard">
              <h2>Magasin</h2>
            </div>
            <div class="card-body">
              <p>21 Rue du Vieux-Pont</p>
              <p>49290 Chalonnes-sur-Loire</p>
              <img class="magasin" src="../assets/devant.jpg" alt="photo du magasin de Chalonnes" title="Photo du magasin microrepaire de Chalonnes sur loire">
            </div>
          </div>
        </div>
        <div class="col-md-10 col-lg-4">
          <div class="card shadow-lg rounded">
            <div class="card-header couleurCard">
              <h2>Localisation</h2>
            </div>
            <div class="card-body">
              <div class="embed-responsive-21by9">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d173070.15612684467!2d-0.8635889318464071!3d47.32955139314257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48062ec108150759%3A0x62954f8388632878!2s21%20Rue%20du%20Vieux%20Pont%2C%2049290%20Chalonnes-sur-Loire!5e0!3m2!1sfr!2sfr!4v1612596557953!5m2!1sfr!2sfr" width="500" height="500" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Acceuil',
  data () {
    return {
      options: {
        height: '400px',
        width: '1024px',
      }
    }
  },
  components: {

  },

}
  

</script>

<style scoped>

.fond {
  background: rgb(52,58,64);
  background: linear-gradient(180deg, rgba(52,58,64,1) 0%, rgb(240, 244, 247) 100%);
}
.image-fond {
  background: url("../assets/fondpage2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
}
ul {
  list-style-type: none;
}
.image img {
	width : 100%;
	height: 100%;
  max-height: 95%;
}
.magasin {
  width: 380px;
}
.couleurCard {
  background: #343A40;
  color: #fff;
}
iframe {
  width: 100%;
}
.magasin {
  width: 100%;
}
p, li {
  font-size: 2vh;
}


@media (max-width: 1200px) {

h2 {
  font-size: 3.5vh;
}
.image img {
	width : 100%;
	height: 100%;
  max-height: 95%;
}
}


</style>