<template>
  <div class="fond">
    <div class="container-fluid">
      <div class="row placetitre">
        <div class="col-12">
          <h2 class="text-center couleur">Services</h2>
          <p class="couleur textBase">Réparation toutes marques PC, MAC, Android, Linux<br/>
            Une réponse téléphonique claire et précise, un technicien chez vous rapidement</p>
        </div>
      </div>
    </div>
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-1">
          </div>
          <div class="col-lg-3">
            <div class="card repa">
              <img class="taillerepa" src="../assets/page1reparation.png" alt="Logo de réparation">
              <h3>Réparation</h3>
            </div>
          </div>
          <div class="col-lg-7 liste1">
            <div class="card bg-transparent">
              <div class="boite1">
                <div>
                  <div class="shadow rounded contenu">
                    <p>Réparation toutes marques et tous supports (ordinateurs fixes et portables, tablettes)</p>
                  </div>
                  <div class="shadow rounded contenu">
                    <p>Récupération de données, sauvegarde</p>
                  </div>
                  <div class="shadow rounded contenu">
                    <p>Amélioration des performances système</p>
                  </div>
                  <div class="shadow rounded contenu">
                    <p>Changement des composants</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sect2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-1">
          </div>
          <div class="col-lg-7 liste2">
            <div class="card bg-transparent">
              <div class="boite2">
                <div>
                  <div class="shadow rounded contenu">
                    <p>Changement des composants :</p>   
                  </div>
                  <div class="shadow rounded contenu">
                    <p>- Disque dur, port USB, mémoire</p>
                  </div>
                  <div class="shadow rounded contenu">
                    <p>- Carte mère, carte Wifi, carte bluetooth</p>
                  </div>
                  <div class="shadow rounded contenu">
                    <p>- Clavier, écran</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 onglet2">
            <div class="card repa2">
              <img class="taillerepa" src="../assets/page1changement.png" alt="Logo de changement">
              <h3>Changement</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div class="row changement">
          <div class="col-lg-1">

          </div>
          <div class="col-lg-3">
            <div class="card repa3">
              <img class="taillerepa" src="../assets/page1installation.png" alt="Logo de changement">
              <h3>Installation</h3>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="card bg-transparent">
              <div class="boite3">
                <div>
                  <div class="shadow rounded contenu">
                    <p>Installation logiciels PC Windows PC MAC</p>
                  </div>
                  <div class="shadow rounded contenu">
                    <p>Mise en route d'Internet sur tous supports (PC, tablettes, smartphones)</p>
                  </div>
                  <div class="shadow rounded contenu">
                    <p>Installation des systèmes d'exploitation</p>
                  </div>
                  <div class="shadow rounded contenu">
                    <p>Vérification de la sécurité du système</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div class="row changement">
          <div class="col-lg-1">
          </div>
          <div class="col-lg-7 liste4">
            <div class="card bg-transparent">
              <div class="boite4">
                <div>
                  <div class="shadow rounded contenu">
                    <p>Configuration sur mesure d'un PC et montage selon vos besoins dans les domaines suivants :</p>   
                  </div>
                  <div class="shadow rounded contenu">
                    <p>- CAO</p>
                  </div>
                  <div class="shadow rounded contenu">
                    <p>- Gamer</p>
                  </div>
                  <div class="shadow rounded contenu">
                    <p>- Montage vidéo</p>
                  </div>
                  <div class="shadow rounded contenu">
                    <p>- Bureautique</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 onglet4">
            <div class="card repa4">
              <img class="taillerepa" src="../assets/configurationrepa.png" alt="Logo de changement">
              <h3>Configuration</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div class="row changement">
          <div class="col-lg-1">
          </div>
          <div class="col-lg-3">
            <div class="card repa5">
              <img class="taillerepa" src="../assets/page1sauvegarde.png" alt="Logo de changement">
              <h3>Sauvegarde</h3>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="card bg-transparent">
              <div class="boite5">
                <div>
                  <div class="shadow rounded contenu">
                    <p>Sauvegarde Personalisé</p>
                  </div>
                  <div class="shadow rounded contenu">
                    <p>Sauvegarde image du PC</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div class="row changement">
          <div class="col-lg-1">
          </div>
          <div class="col-lg-7 liste6">
            <div class="card bg-transparent">
              <div class="boite6">
                <div>
                  <div class="shadow rounded contenu">
                    <p>Nettoyage et protection</p>   
                  </div>
                  <div class="shadow rounded contenu">
                    <p>Bilan de santé de votre ordinateur</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 onglet6">
            <div class="card repa6">
              <img class="taillerepa" src="../assets/page1nettoyage.png" alt="Logo de changement">
              <h3>Nettoyage</h3>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>
</template>

<script>



export default {
  name: 'ReparationInfo',
  data() {
    return {

    }
  },
  methods: {
    
  },
  components: {

  },

}
</script>

<style scoped>

.fond {
  background: rgb(52,58,64);
  background: linear-gradient(180deg, rgba(52,58,64,1) 0%, rgba(239,246,224,1) 100%);
}

.card {
  border-radius: 30px;
}
.fleche {
  width: 10px;
}
.placetitre {
  padding-top: 5%;
}
.repa {
  text-align: center;
  background: #EFF6E0;
  margin-bottom: 3%;
}
img {
  margin: 15px auto 15px auto;
}

.couleur {
  color: #fff;
}
p {
  font-size: 2.1vh;
  font-weight: bold;
}
.taillerepa {
  width: 50%;
  height: 50%;
}
.textBase {
  font-size: 3vh;
  padding: 5% 10% 5% 10%;
}
.boite1 {
  position: relative;
  background-color: #EFF6E0;
  padding-left: 5%;
  padding-top: 1.5%;
  padding-right: 5%;
  border-radius: 30px;
}
.boite1 p {
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-left: 3.5%;
}
.changement {
  padding-top: 1%;
}
.boite2 {
  position: relative;
  background-color: #AEC3B0;
  padding-left: 5%;
  padding-top: 1.5%;
  padding-right: 5%;
  border-radius: 30px;
}
.boite2 p {
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-left: 3.5%;
}
.repa2 {
  text-align: center;
  background: #AEC3B0;
  margin-top: -12%;
}



.boite3 {
  position: relative;
  background-color: #99b3a9;
  padding-left: 5%;
  padding-top: 1.5%;
  padding-right: 5%;
  border-radius: 30px;
}
.boite3 p {
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-left: 3.5%;
}
.repa3 {
  text-align: center;
  background: #99b3a9;
}

.boite4 {
  position: relative;
  background-color: #84a3a1;
  padding-left: 5%;
  padding-top: 1.5%;
  padding-right: 5%;
  border-radius: 30px;
}
.boite4 p {
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-left: 3.5%;
}
.repa4 {
  text-align: center;
  background: #84a3a1;
  margin-top: -1%;
}




.boite5 {
  position: relative;
  background-color: #598392;
  padding-left: 5%;
  padding-top: 1.5%;
  padding-right: 5%;
  border-radius: 30px;
  font-weight: bold;
}
.boite5 p {
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-left: 3.5%;
}
.repa5 {
  text-align: center;
  background: #598392;
  font-weight: bold;
}

.boite6 {
  position: relative;
  background-color: #124559;
  padding-left: 5%;
  padding-top: 1.5%;
  padding-right: 5%;
  border-radius: 30px;
  color: #fff;
}
.boite6 p {
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-left: 3.5%;
}
.repa6 {
  text-align: center;
  background: #124559;
  margin-top: -36%;
  color: #fff;
  margin-bottom: 10%;
}



@media (max-width: 375px){
 .textBase {
  padding-left: 0%;
  padding-right: 0%;
}
.repa2, .repa4, .repa6 {
  margin-top: 0px;
}
.taillerepa {
  width: 30%;
  height: 30%;
}

}
@media only screen and (max-width: 600px) {
.repa2, .repa3, .repa4, .repa5, .repa6 {
  margin-top: 10px;
}
.repa {
  margin-bottom: 0%;
}
.repa6 {
  margin-bottom: 0%;
}
section {
  padding-left: 2%;
  padding-right: 2%;
}
.taillerepa {
  width: 30%;
  height: 30%;
}
.liste2, .liste4, .liste6 {
  order: 2;
}
.onglet2, .onglet4, .onglet6 {
  order: 1;
}
.liste6 {
  margin-bottom: 5%;
}
}
@media (min-width: 601px) and (max-width: 991px) {
.repa2, .repa3, .repa4, .repa5, .repa6 {
  margin-top: 10px;
}
.repa {
  margin-bottom: 0%;
}
.repa6 {
  margin-bottom: 0%;
}
section {
  padding-left: 2%;
  padding-right: 2%;
}
.taillerepa {
  width: 15%;
  height: 15%;
}
.liste2, .liste4, .liste6 {
  order: 2;
}
.onglet2, .onglet4, .onglet6 {
  order: 1;
}
.liste6 {
  margin-bottom: 5%;
}
}
@media (min-width: 992px) and (max-width: 1480px) {
.liste1 {
  margin-bottom: 1%;
}
.repa2 {
margin-top: 0px;
}
}
</style>